'use client'

import headerNavLinks from '@/data/headerNavLinks'
import Logo from '@/data/logo.svg'
import siteMetadata from '@/data/siteMetadata'
import { useEffect, useState } from 'react'
import MeaMenuTitle from './../public/static/images/meamenu-title.svg'
import Link from './Link'
import MobileNav from './MobileNav'
import SearchButton from './SearchButton'
import ThemeSwitch from './ThemeSwitch'
const Header = () => {
  const [hState, sethState] = useState('header-top')

  useEffect(() => {
    let lastVal = 0
    window.onscroll = function () {
      const y = window.scrollY
      if (y > lastVal) {
        sethState('header-down')
      }
      if (y < lastVal) {
        sethState('header-up')
      }
      if (y < 0 || lastVal < 0) {
        sethState('header-top')
      }

      lastVal = y
    }
  }, [])
  return (
    <header
      className={
        'header fixed w-full max-w-3xl flex-1 text-center text-lg font-semibold md:px-0 xl:max-w-6xl  ' +
        hState
      }
    >
      <div className="header-child rounded-0 flex flex-row justify-between bg-customWhiteBackgroundColor dark:bg-customDarkBackgroundColor md:rounded-b-2xl xl:shadow-[1px_3px_26px_3px_rgba(0,0,0,0.1)]">
        <div className="px-2 md:px-0">
          <Link href="/" aria-label={siteMetadata.headerTitle}>
            <div className="flex items-center justify-between">
              <div className="mr-3">
                <Logo />
              </div>
              {typeof siteMetadata.headerTitle === 'string' ? (
                <div className="hidden h-6 text-2xl font-semibold sm:block">
                  <MeaMenuTitle className="h-12 w-52 text-secondary hover:text-secondary-500 dark:text-primary dark:hover:text-primary-200" />
                </div>
              ) : (
                siteMetadata.headerTitle
              )}
            </div>
          </Link>
        </div>
        <div className="flex items-center space-x-4 px-1 leading-5 sm:space-x-6">
          {headerNavLinks
            .filter((link) => link.href !== '/')
            .map((link, idx) => (
              <div key={idx}>
                <Link
                  key={link.title}
                  href={link.href}
                  className="hidden font-medium hover:text-secondary-500 dark:hover:text-primary-300 sm:block"
                >
                  {link.title}
                </Link>
              </div>
            ))}
          <SearchButton />
          <ThemeSwitch />
          <MobileNav />
        </div>
      </div>
    </header>
  )
}

export default Header
