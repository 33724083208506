import(/* webpackMode: "eager" */ "/vercel/path0/app/theme-providers.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/ClarityAnalytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/custom/kbar/KBar.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/GoogleAnalytics.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Header.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/components/Link.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/css/tailwind.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mep-agency/next-iubenda/src/components/ConsentAwareWrapper/index.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mep-agency/next-iubenda/src/components/IubendaCookieSolutionBanner.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mep-agency/next-iubenda/src/contexts/IubendaContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./font/CircularStd-Black.otf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"./font/CircularStd-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./font/CircularStd-Book.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./font/CircularStd-Light.otf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./font/CircularStd-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"}],\"display\":\"swap\",\"variable\":\"--font-mea-menu\"}],\"variableName\":\"MEA_MENU_FONT\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/pliny/chunk-O6XQYU4T.js")